import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import CTA from '../components/CTA'
import Header from '../components/Header'
import { FAQContainer, FAQs } from '../components/FAQs'
import { AboutContact } from '.'

export default () => (
  <Layout>
    <Helmet>
      <title>Council Approval - Just Patios</title>
      <meta
        name="description"
        content="Just Patios can arrange your council approvals when you build a Patio, Deck, Carport or Room Enclosure with us. Call us today for a free quote!"
      />
    </Helmet>
    <Header>
      <h1>Council Approval</h1>
    </Header>
    <FAQContainer>
      <FAQs>
        <h4 style={{ color: '#F69F1A' }}>
          Just Patios Council Approval Process
        </h4>
        <p>
          As with all modern day approval processes for your patio we engage a
          private certifier to obtain a building approval. Local Councils are
          responsible for archiving of documentation and relaxation permits. The
          process starts with Just Patios preparing a site plan and lodging
          relevant paperwork and engineering to our certification company for
          them to assess. Our certifier will then conduct all relevant searches
          relating to your house in particular i.e. location of sewerage,
          stormwater and easements. This process generally takes up to 2 weeks
          to complete.
        </p>

        <p>
          If a relaxation is required we will inform you at your initial quote
          and generally extra fees will need to be paid. Extra time will also
          need to be allowed for the approval process as an inspector from your
          local council authority will need to inspect the site prior to
          approval. After completion of construction a final inspection is
          carried out and forwarded to the owner, council and Just Patios.
        </p>
      </FAQs>
    </FAQContainer>

    <AboutContact />
  </Layout>
)
